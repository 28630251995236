import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { signOutForce } from 'hooks/auth';

interface RegisterInterceptorProps {
  requestPinToken: (config: AxiosRequestConfig) => boolean;
}
interface ApiInstance extends AxiosInstance {
  registerInterceptor: ({ requestPinToken }: RegisterInterceptorProps) => () => void;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API ?? 'https://api.storycrm.app',
  headers: { 'Access-Control-Allow-Origin': '*' },
}) as ApiInstance;

// TODO: Colocar o interceptor AppError para o alert

api.registerInterceptor = ({ requestPinToken }) => {
  const interceptor = api.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      if (error.response?.status === 401) {
        signOutForce(true);
      }

      if (error.response?.status === 403) {
        const originalRequest = error.config;
        const allowedToRequestPin = requestPinToken(originalRequest);

        if (!allowedToRequestPin) {
          signOutForce(true);
        }
      }

      return Promise.reject(error);
    },
  );

  return () => {
    axios.interceptors.response.eject(interceptor);
  };
};

export default api;
