import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Withdrawal = Loadable(lazy(() => import('modules/withdrawal/pages/Withdrawal')));

const SearchIndividual = Loadable(lazy(() => import('modules/withdrawal/pages/WithdrawalSearchIndividual')));

export const withdrawalsRouter = [
  {
    path: '/withdrawals',
    element: <Withdrawal />,
  },
  {
    path: '/withdrawals/individual/search',
    element: <SearchIndividual />,
  },
];
