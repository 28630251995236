import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconMenu2 } from '@tabler/icons';

import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import SearchSection from './SearchSection';

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
  enterpriseName?: string;
}

const Header: React.FC<HeaderProps> = ({ handleLeftDrawerToggle, enterpriseName }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: 250,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'block' },
            flexGrow: 1,
          }}>
          <LogoSection />
        </Box>

        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
              color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit">
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <SearchSection />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ paddingRight: 4 }}>
        <Typography textTransform="capitalize" variant="subtitle1" fontSize={16}>
          {enterpriseName?.toLowerCase()}
        </Typography>
      </Box>

      <ProfileSection />
    </>
  );
};

export default Header;
