import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Customer = Loadable(lazy(() => import('modules/customers/pages/Customer')));

export const customerRoutes = [
  {
    path: '/customers',
    element: <Customer />,
  },
];
