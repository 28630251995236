import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { useAuth } from 'hooks/auth';

interface QuotaBarProps {
  percentage: number;
  onClose: () => void;
}

const billingRoles = ['module-billing-read', 'module-billing-global'];

export function QuotaBar({ percentage }: QuotaBarProps) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const rolePermission = user.permission.roles?.some(role => billingRoles.includes(role));

  const theme = useTheme();

  const handleRequestChange = () => {
    navigate('/billing?showRequestQuotaModal=true');
  };

  return (
    <Box
      width="100vw"
      bgcolor={theme.palette.dark.main}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingX={5}
      paddingY={2}
      borderBottom={`solid 2px ${theme.palette.primary.main}`}
      className="quota-bar">
      <Box
        paddingTop={1}
        sx={{
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        }}>
        <Typography variant="h3" fontSize={15}>
          Alerta em conta StoryCRM
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={3}>
        {!rolePermission ? (
          <Typography variant="h3" fontSize={13}>
            Contate o seu administrador
          </Typography>
        ) : (
          <>
            <Typography variant="h3" fontSize={13}>
              Essa conta atingiu {percentage}% de uso
            </Typography>
            <Button variant="contained" onClick={handleRequestChange} size="small">
              Solicitar alteração
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
