import { IconUsers, IconManualGearbox, IconPresentationAnalytics, IconFileInvoice } from '@tabler/icons';

const manager = {
  id: 'manager-route',
  type: 'group',
  children: [
    {
      id: 'enterprise',
      title: 'Empresa',
      type: 'item',
      url: '/enterprises',
      icon: IconUsers,
    },
    {
      id: 'companies',
      title: 'Unidades',
      type: 'item',
      url: '/companies',
      icon: IconPresentationAnalytics,
    },
    {
      id: 'billing',
      title: 'Financeiro',
      type: 'item',
      url: '/billing',
      icon: IconFileInvoice,
      roles: ['module-billing-read', 'module-billing-global'],
    },
    {
      id: 'manager-role',
      title: 'Gerenciador',
      type: 'collapse',
      icon: IconManualGearbox,
      children: [
        {
          id: 'manager-users',
          title: 'Usuários',
          type: 'item',
          url: '/manager/users',
        },
        {
          id: 'manager-permissions',
          title: 'Permissões',
          type: 'item',
          url: '/manager/permissions',
        },
      ],
    },
  ],
};

export default manager;
