import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { generatePinToken } from 'shared/services/handles/generate-pin-token';
import AnimateButton from 'shared/ui-component/extended/AnimateButton';

import { useToast } from 'hooks/toast';

interface RequestPinModalProps extends DialogProps {
  accessType: string;
  requestPayload: {
    body: any;
    url: string;
  };
  onSendCode: (token: string) => void;
  handleCloseDialog: () => void;
}

export function RequestPinModal({
  accessType,
  requestPayload,
  onSendCode,
  handleCloseDialog,
  ...rest
}: RequestPinModalProps) {
  const [code, setCode] = useState('');
  const { addToast } = useToast();

  const handleSendCode = async () => {
    try {
      const { token } = await generatePinToken({
        access_pin: code,
        access_type: accessType,
        request_payload: requestPayload,
      });

      onSendCode(token);
    } catch (error) {
      addToast({
        title: 'Algo errado aconteceu',
        description: 'Ocorreu um erro na solicitação',
        type: 'error',
      });
    }
  };

  return (
    <Dialog {...rest} maxWidth="xs">
      <DialogTitle>PIN de segurança</DialogTitle>

      <DialogContent>
        <Typography variant="body1" display="flex" alignItems="center" fontSize="1rem">
          Você não possui permissão a essa funcionalidade, por favor, solicite o código PIN de um usuário com permissão
          para prosseguir
        </Typography>

        <Stack marginTop={3} gap={1}>
          <Box marginBottom={2}>
            <TextField
              fullWidth
              type="password"
              value={code}
              placeholder="Código de 6 dígitos"
              onChange={event => setCode(event.target.value)}
            />
          </Box>

          <AnimateButton>
            <Button
              disableElevation
              onClick={handleSendCode}
              fullWidth
              size="large"
              type="button"
              variant="contained"
              color="secondary">
              Enviar
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              disableElevation
              onClick={handleCloseDialog}
              fullWidth
              size="large"
              type="button"
              variant="outlined"
              color="primary">
              Cancelar
            </Button>
          </AnimateButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
