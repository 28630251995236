import React, { createContext, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import * as amplitude from '@amplitude/analytics-browser';
import { appConfig } from 'config/app';
import { KeyedObject } from 'interfaces/global.interface';
import jwtDecode from 'jwt-decode';

import api from '../shared/services/api';

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface User {
  phone?: boolean;
  id: string;
  name: string;
  email: string;
  username: string;
  permission: {
    name: string;
    description: string;
    roles: string[];
  };
  enterprise: {
    name: string;
    org_provider_enabled: boolean;
  };
}

interface AuthContextData {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  user: User;
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

// let authChannel: BroadcastChannel;

export function signOutForce(forceRedirect = false): void {
  localStorage.removeItem('@storytrackin:token');
  localStorage.removeItem('@storytrackin:user');

  if (forceRedirect === true) {
    window.location.href = '/login';
  }
  // authChannel.postMessage('signOut');
}

function validateToken(token: string): boolean {
  if (!token) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(token);

  return decoded.exp > Date.now() / 1000;
}

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@storytrackin:token');
    const user = localStorage.getItem('@storytrackin:user');

    const isValidAccessToken = token && user && validateToken(token);

    if (isValidAccessToken) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/v1/sessions', {
      username: email,
      password,
    });

    const { user, token } = response.data;

    amplitude.setUserId(user.id);
    amplitude.track('Sign In');

    localStorage.setItem('@storytrackin:token', token);
    localStorage.setItem('@storytrackin:user', JSON.stringify(user));

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    setData({ token, user });

    navigate(appConfig.defaultPlatformPath, { replace: true });
  }, []);

  // useEffect(() => {
  //   authChannel = new BroadcastChannel('auth');

  //   // Valid signout in other pages
  //   // authChannel.onmessage = (message) => {
  //   //   switch (message.data) {
  //   //     case 'signOut':
  //   //       signOutForce();
  //   //       break;
  //   //     default:
  //   //       break;
  //   //   }
  //   // };
  // }, []);

  const signOut = useCallback(() => {
    signOutForce();

    setData({} as AuthState);

    navigate(appConfig.defaultPath, { replace: true });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user: data.user,
        token: data.token,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
