import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import { Loadable } from 'shared/ui-component/Loadable';

import { AuthRoutes } from './AuthRoutes';
import { DoItYourselfRoutes } from './DoItYourselfRoutes';
import { MainRoutes } from './MainRoutes';

export function Routes(): any {
  const MaintenanceError = Loadable(lazy(() => import('modules/maintenance/Error')));

  return useRoutes([
    MainRoutes,
    AuthRoutes,
    DoItYourselfRoutes,
    {
      path: '*',
      element: <MaintenanceError />,
    },
  ]);
}
