import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const SegmentList = Loadable(lazy(() => import('modules/segments/pages/Segment')));
const SegmentSave = Loadable(lazy(() => import('modules/segments/pages/SegmentSave')));
const SegmentQuery = Loadable(lazy(() => import('modules/segments/pages/SegmentQuery')));

export const segmentRouter = [
  {
    path: '/segments',
    element: <SegmentList />,
  },
  {
    path: '/segments/query-builder',
    element: <SegmentQuery />,
  },
  {
    path: '/segments/query-builder/save',
    element: <SegmentSave />,
  },
];
