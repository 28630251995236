import { Link } from 'react-router-dom';

import { ButtonBase } from '@mui/material';
import { appConfig } from 'config/app';

import Logo from 'shared/ui-component/Logo';

const LogoSection: React.FC = () => (
  <ButtonBase disableRipple component={Link} to={appConfig.defaultPath}>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
