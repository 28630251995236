// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';

export function ChatWootWidget() {
  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right',
      locale: 'pt',
      type: 'standard',
    };

    (function (d, t) {
      const BASE_URL = 'https://chat.storycrm.co';
      const g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'oKJ6vbyXg2mCdfff1DTR8MFg',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }, []);

  return null;
}
