/* eslint-disable import-helpers/order-imports */
import 'assets/scss/style.scss';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'shared/services/store';

import App from 'App';
import { appConfig } from 'config/app';
import { ChatWootWidget } from 'shared/components/ChatWootWidget';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={appConfig.basename}>
      <App />
    </BrowserRouter>
    <ChatWootWidget />
  </Provider>,
  document.getElementById('root'),
);
