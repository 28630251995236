import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as amplitude from '@amplitude/analytics-browser';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DefaultRootStateProps } from 'interfaces/global.interface';
import { NavigationScroll } from 'layout/NavigationScroll';
import { Routes } from 'routes';

import { theme } from 'shared/styles/themes';

import AppProvider from 'hooks';

const App: React.FC = () => {
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);

  useEffect(() => {
    const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (amplitudeKey) {
      amplitude.init(amplitudeKey, {
        defaultTracking: true,
      });
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AppProvider>
            <>
              <Routes />
            </>
          </AppProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
