import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Giftback = Loadable(lazy(() => import('modules/strategies/pages/Promotion')));
const Reward = Loadable(lazy(() => import('modules/strategies/pages/Reward')));
const RewardVault = Loadable(lazy(() => import('modules/strategies/pages/RewardVault')));
const RewardSave = Loadable(lazy(() => import('modules/strategies/pages/RewardSave')));

const strategiesRouter = [
  {
    path: '/strategies/giftback',
    element: <Giftback />,
  },
  {
    path: '/strategies/reward',
    element: <Reward />,
  },
  {
    path: '/strategies/reward/save',
    element: <RewardSave />,
  },
  {
    path: '/strategies/reward/save/:id',
    element: <RewardSave />,
  },
  {
    path: '/strategies/reward/vault',
    element: <RewardVault />,
  },
];

export default strategiesRouter;
