import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Enterprise = Loadable(lazy(() => import('modules/enterprises/pages/Enterprise')));

export const enterpriseRoutes = [
  {
    path: '/enterprises',
    element: <Enterprise />,
  },
];
