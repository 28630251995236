import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Campaign = Loadable(lazy(() => import('modules/campaings/pages/Campaign')));
const SaveCampaign = Loadable(lazy(() => import('modules/campaings/pages/Campaign/CampaingCreate')));
const CampaignIndividualCreate = Loadable(lazy(() => import('modules/campaings/pages/CampaignIndividualCreate')));

export const campaignRoutes = [
  {
    path: '/campaings',
    element: <Campaign />,
  },
  {
    path: '/campaings/create',
    element: <SaveCampaign />,
  },
  {
    path: '/campaings/individual/create',
    element: <CampaignIndividualCreate />,
  },
];
