import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Permission = Loadable(lazy(() => import('modules/users/pages/Permission')));

const User = Loadable(lazy(() => import('modules/users/pages/User')));

const managerRouter = [
  {
    path: '/manager/permissions',
    element: <Permission />,
  },
  {
    path: '/manager/users',
    element: <User />,
  },
];

export default managerRouter;
