import {
  IconSmartHome,
  IconUsers,
  IconDeviceAnalytics,
  IconCalendar,
  IconApps,
  IconPresentationAnalytics,
  IconListSearch,
  IconShoppingCartDiscount,
  IconDiscount,
  IconPackage,
} from '@tabler/icons';

const main = {
  id: 'main-route',
  type: 'group',
  pattern: 'module.dashboard.index',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      pattern: 'dashboard.module.show',
      url: '/dashboard',
      icon: IconSmartHome,
    },
    {
      id: 'withdrawal',
      title: 'Ofertas',
      type: 'collapse',
      pattern: 'stategy.module.show',
      icon: IconShoppingCartDiscount,
      // TODO: Fazer as permissões do frontend para só exibir o necessário
      children: [
        {
          id: 'withdrawal-search',
          title: 'Consultar disponíveis',
          type: 'item',
          pattern: 'stategy.cashback.show',
          url: '/withdrawals',
        },
        {
          id: 'withdrawal-search-individual',
          title: 'Consulta individual',
          type: 'item',
          pattern: 'stategy.cashback.show',
          url: '/withdrawals/individual/search',
        },
        {
          id: 'withdrawal-create',
          title: 'Cadastrar nova',
          pattern: 'stategy.member-get-member.show',
          type: 'item',
          url: '/campaings/individual/create',
        },
      ],
    },
    {
      id: 'stategy',
      title: 'Estratégias',
      type: 'collapse',
      pattern: 'stategy.module.show',
      url: '/strategies',
      icon: IconDiscount,
      children: [
        {
          id: 'cashback',
          title: 'Giftback',
          type: 'item',
          pattern: 'stategy.cashback.show',
          url: '/strategies/giftback',
        },
        {
          id: 'reward',
          title: 'Recompensas',
          type: 'item',
          pattern: 'stategy.reward.show',
          url: '/strategies/reward',
          roles: ['module-reward-read', 'module-reward-global'],
        },
      ],
    },
    {
      id: 'customer',
      title: 'Clientes',
      type: 'item',
      pattern: 'contact.module.show',
      url: '/customers',
      icon: IconUsers,
    },
    {
      id: 'product',
      title: 'Produtos',
      type: 'collapse',
      pattern: 'product.module.show',
      icon: IconPackage,
      children: [
        {
          id: 'product-my',
          title: 'Meus produtos',
          type: 'item',
          pattern: 'stategy.cashback.show',
          url: '/products',
        },
        {
          id: 'product-reward',
          title: 'Externos',
          type: 'collapse',
          roles: ['module-order-extends-product-external-global'],
          pattern: 'stategy.reward.show',
          children: [
            {
              id: 'product-reward-my',
              title: 'Aprovações de acompanhamento',
              type: 'item',
              pattern: 'stategy.cashback.show',
              url: '/products/enterprise-provider',
            },
            {
              id: 'product-reward-reports',
              title: 'Relatórios',
              type: 'item',
              pattern: 'stategy.reward.show',
              url: '/products/enterprise-provider/report',
            },
          ],
        },
      ],
    },
    {
      id: 'segment',
      title: 'Segmentação',
      type: 'item',
      pattern: 'segment.module.show',
      url: '/segments',
      icon: IconListSearch,
    },
    {
      id: 'inteligy',
      title: 'Inteligência',
      disabled: true,
      type: 'item',
      url: '/',
      icon: IconDeviceAnalytics,
    },
    {
      id: 'campaing',
      title: 'Campanhas',
      type: 'item',
      pattern: 'campaing.module.show',
      url: '/campaings',
      icon: IconCalendar,
    },
    {
      id: 'marketing',
      title: 'Marketing',
      disabled: true,
      type: 'item',
      url: '/marketing',
      icon: IconPresentationAnalytics,
    },
    {
      id: 'integration',
      title: 'Integrações',
      disabled: true,
      type: 'item',
      url: '/integration',
      icon: IconApps,
    },
  ],
};

export default main;
