import api from '../api';

interface GeneratePinTokenParams {
  access_pin: string;
  access_type: string;
  request_payload: {
    body: any;
    url: string;
  };
}

interface GeneratePinTokenResponse {
  token: string;
}

export async function generatePinToken({
  access_pin,
  access_type,
  request_payload,
}: GeneratePinTokenParams): Promise<GeneratePinTokenResponse> {
  const response = await api.post('/v1/users/access-pin/request', { access_pin, access_type, request_payload });

  return response.data;
}
