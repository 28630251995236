import { createContext, useContext, useEffect, useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import { RequestPinModal } from 'shared/components/RequestPinDialog';
import api from 'shared/services/api';

interface RequestPinModalContextData {
  setOpen: (value: boolean) => void;
  setAccessType: (value: string) => void;
}

const RequestPinModalContext = createContext<RequestPinModalContextData>({} as RequestPinModalContextData);

interface RequestPinModalProviderProps {
  children: React.ReactNode;
}

export function RequestPinModalProvider({ children }: RequestPinModalProviderProps) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<AxiosRequestConfig>({});
  const [accessType, setAccessType] = useState('');

  const requestPinToken = (axiosConfig: AxiosRequestConfig) => {
    // FIXME: Add on header later
    if (!axiosConfig.params?.allow_retry_on_fail) {
      return false;
    }

    setOpen(true);
    setRequest(axiosConfig);
    return true;
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const onSendCode = async (token: string) => {
    request.headers['Authorization'] = `Bearer ${token}`;
    await api(request);
    window.location.reload();
    handleCloseDialog();
  };

  useEffect(() => {
    const subscribe = api.registerInterceptor({
      requestPinToken,
    });

    return () => {
      subscribe();
      setRequest({});
    };
  }, []);

  return (
    <RequestPinModalContext.Provider
      value={{
        setOpen,
        setAccessType,
      }}>
      {children}
      {open && (
        <RequestPinModal
          open
          accessType={accessType}
          requestPayload={{ body: request.data, url: request.url! }}
          onSendCode={onSendCode}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </RequestPinModalContext.Provider>
  );
}

export function useRequestPin() {
  return useContext(RequestPinModalContext);
}
