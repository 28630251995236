import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import { AuthGuard } from 'routes/guard/AuthGuard';

import { billingRouter } from 'modules/billing/routes/index.routes';
import { campaingRouter } from 'modules/campaings/routes/index.routes';
import { companiesRouter } from 'modules/companies/routes/index.routes';
import { customerRouter } from 'modules/customers/routes/index.routes';
import { enterpriseRouter } from 'modules/enterprises/routes/index.routes';
import { productRouter } from 'modules/products/routes/index.routes';
import { segmentsRouter } from 'modules/segments/routes/index.routes';
import { strategyRouter } from 'modules/strategies/routes/index.routes';
import { usersRouter } from 'modules/users/routes/index.routes';
import { withdrawalRouter } from 'modules/withdrawal/routes/index.routes';

import { Loadable } from 'shared/ui-component/Loadable';

const DashboardPage = Loadable(lazy(() => import('modules/dashboard/pages/Dashboard')));

export const MainRoutes = {
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    ...usersRouter,
    ...companiesRouter,
    ...productRouter,
    ...strategyRouter,
    ...segmentsRouter,
    ...customerRouter,
    ...enterpriseRouter,
    ...withdrawalRouter,
    ...campaingRouter,
    ...billingRouter,
    {
      path: '/dashboard',
      element: <DashboardPage />,
    },
  ],
};

export default MainRoutes;
