import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const AccountProfile = Loadable(lazy(() => import('modules/users/pages/AccountProfile')));

const userRouter = [
  {
    path: '/users/account',
    element: <AccountProfile />,
  },
];

export default userRouter;
