import React from 'react';

import { Divider, List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GenericCardProps } from 'interfaces/global.interface';

import { useAuth } from 'hooks/auth';

import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

export interface NavGroupProps {
  item: {
    roles?: string[];
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
  };
}

const NavGroup: React.FC<NavGroupProps> = ({ item }) => {
  const theme = useTheme();
  const { user } = useAuth();

  const items = item.children
    ?.filter(item => {
      if (item.roles) {
        item.roles.push('developer-debug');
        return item.roles.find(role => user.permission.roles.includes(role));
      }

      return item;
    })
    .map(menu => {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} roles={user.permission.roles} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }>
        {items}
      </List>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

export default NavGroup;
