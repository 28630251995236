import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { IconChevronRight } from '@tabler/icons';
import { DefaultRootStateProps } from 'interfaces/global.interface';
import navigation from 'menu-items';

import { User } from 'modules/users/entities/User';

import { SET_MENU } from 'shared/services/store/actions';
import { drawerWidth } from 'shared/services/store/constant';
import Breadcrumbs from 'shared/ui-component/extended/Breadcrumbs';

import { useLocalStorage } from 'hooks/local-storage';
import { useQuota } from 'hooks/quota';

import Header from './Header';
import { QuotaBar } from './QuotaBar';
import Sidebar from './Sidebar';

// assets

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: React.FC = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const [storagedUser] = useLocalStorage<User>('@storytrackin:user');

  // Handle left drawer
  const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);
  const dispatch = useDispatch();
  const { percentage } = useQuota();

  const [showQuotaBar, setShowQuotaBar] = useState(true);

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const handleCloseQuotaBar = () => {
    setShowQuotaBar(false);
  };

  const quotaBarOpen = percentage >= 70 && showQuotaBar;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
        }}>
        {quotaBarOpen && <QuotaBar percentage={percentage} onClose={handleCloseQuotaBar} />}

        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} enterpriseName={storagedUser.enterprise?.name} />
        </Toolbar>
      </AppBar>
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} quotaBarOpen={quotaBarOpen} />
      <Main theme={theme} open={leftDrawerOpened}>
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
