import { MinimalLayout } from 'layout/MinimalLayout';
import { NavMotion } from 'layout/NavMotion';

import { doItYourSelfRouter } from 'modules/do-it-yourself/routes/index.routes';

export const DoItYourselfRoutes = {
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [...doItYourSelfRouter],
};
