import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Session = Loadable(lazy(() => import('modules/authorization/pages/Session')));
const ForgotPassword = Loadable(lazy(() => import('modules/authorization/pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('modules/authorization/pages/ResetPassword')));
const Journey = Loadable(lazy(() => import('modules/authorization/pages/Journey')));
const ValidatePhone = Loadable(lazy(() => import('modules/authorization/pages/ValidatePhone')));

const sessionRouter = [
  {
    path: '/',
    element: <Session />,
  },
  {
    path: '/login',
    element: <Session />,
  },
  {
    path: '/reset-password/:id',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/journey',
    element: <Journey />,
  },
  {
    path: '/journey/validate-phone',
    element: <ValidatePhone />,
  },
];

export default sessionRouter;
