import { PaletteMode } from '@mui/material';

interface ConfigProps {
  basename: string;
  defaultPath: string;
  defaultPlatformPath: string;
  fontFamily: string;
  borderRadius: number;
  outlinedFilled: boolean;
  theme: PaletteMode;
  presetColor: string;
}

const appConfig: ConfigProps = {
  basename: '',
  defaultPath: '/',
  defaultPlatformPath: '/dashboard',
  fontFamily: `'Mulish', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'dark',
  presetColor: 'default',
};

export { appConfig };
