import { NavItemType } from 'interfaces/global.interface';

import main from './main';
import manager from './manager';
import submenu from './submenu';

const menuItems: { items: NavItemType[] } = {
  items: [main, manager, submenu],
};

export default menuItems;
