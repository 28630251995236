import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';

import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { QUOTA_OPEN } from 'shared/services/store/actions';
import { drawerWidth } from 'shared/services/store/constant';

import LogoSection from '../LogoSection';
import MenuList from './MenuList';

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
  quotaBarOpen?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ drawerOpen, drawerToggle, window, quotaBarOpen }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [sidebarHeight, setSidebarHeight] = useState(88);
  const dispatch = useDispatch();

  useEffect(() => {
    const element = document.querySelector('.quota-bar');

    if (!element) {
      dispatch({ type: QUOTA_OPEN, mainContentMoreHeight: 0 });
      setSidebarHeight(88);
      return;
    }

    const elementHeight = element.clientHeight;

    dispatch({ type: QUOTA_OPEN, mainContentMoreHeight: elementHeight });

    setSidebarHeight(prev => prev + elementHeight);
  }, [quotaBarOpen]);

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}>
          <MenuList />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: `${sidebarHeight}px`,
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit">
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
