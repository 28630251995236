import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const CustomerJourney = Loadable(lazy(() => import('modules/do-it-yourself/pages/CustomerJourney')));

export const customerSelfRoutes = [
  {
    path: '/journey/customer',
    element: <CustomerJourney />,
  },
];
