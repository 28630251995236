import { useCallback, useState } from 'react';

export function useLocalStorage<T = any>(key: string, initialValue?: T): [T, (value: T) => void, () => void] {
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setState(value);
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  const removeValue = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  return [state, setValue, removeValue];
}
