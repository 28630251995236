import React from 'react';

import { AuthProvider } from './auth';
import { QuotaProvider } from './quota';
import { RequestPinModalProvider } from './request-pin-modal';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <RequestPinModalProvider>
        <QuotaProvider>{children}</QuotaProvider>
      </RequestPinModalProvider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
