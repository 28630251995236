import api from '../api';

export interface ShowEnterpriseQuotaStateResponse {
  percentage_usage: number;
  current_usage: number;
  max_usage: number;
  quotas: {
    current_usage: number;
    max_usage: number;
    percentage_usage: number;
  }[];
}

export async function showEnterpriseQuotaState(): Promise<ShowEnterpriseQuotaStateResponse> {
  const response = await api.get('/v1/enterprises/quota/state');

  return response.data;
}
