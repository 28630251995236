import { IconHelp, IconUser } from '@tabler/icons';

const other = {
  id: 'settings-group',
  type: 'group',
  children: [
    {
      id: 'user-account',
      title: 'Meu perfil',
      type: 'item',
      url: '/users/account',
      icon: IconUser,
    },
    {
      id: 'suport',
      title: 'Suporte',
      type: 'item',
      url: '/suport',
      icon: IconHelp,
      disabled: true,
    },
  ],
};

export default other;
