import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Product = Loadable(lazy(() => import('modules/products/pages/Product')));
const ProductEnterpriseProvider = Loadable(lazy(() => import('modules/products/pages/ProductEnterpriseProvider')));
const ProductEnterpriseProviderReport = Loadable(
  lazy(() => import('modules/products/pages/ProductEnterpriseProviderReport')),
);

export const productRoutes = [
  {
    path: '/products',
    element: <Product />,
  },
  {
    path: '/products/enterprise-provider',
    element: <ProductEnterpriseProvider />,
  },
  {
    path: '/products/enterprise-provider/report',
    element: <ProductEnterpriseProviderReport />,
  },
];
