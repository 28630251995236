import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Billing = Loadable(lazy(() => import('modules/billing/pages/Billing')));

export const billingRoutes = [
  {
    path: '/billing',
    element: <Billing />,
  },
];
