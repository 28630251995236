import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const CampaignIndividual = Loadable(lazy(() => import('modules/do-it-yourself/pages/CampaignIndividual')));

export const campaignIndividualRoutes = [
  {
    path: '/do-it-yourself/campaign-individual/:company_id/:enterprise_strategy_id',
    element: <CampaignIndividual />,
  },
];
