import { createContext, useContext, useEffect, useState } from 'react';

import {
  showEnterpriseQuotaState,
  ShowEnterpriseQuotaStateResponse,
} from 'shared/services/handles/show-enterprise-quota-state';

import { useAuth } from './auth';

interface QuotaContextData {
  percentage: number;
  maxUsage: number;
  currentUsage: number;
}

const QuotaContext = createContext<QuotaContextData>({} as QuotaContextData);

interface QuotaProviderProps {
  children: React.ReactNode;
}

export function QuotaProvider({ children }: QuotaProviderProps) {
  const [data, setData] = useState<ShowEnterpriseQuotaStateResponse>({} as ShowEnterpriseQuotaStateResponse);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    (async () => {
      const responseData = await showEnterpriseQuotaState();

      setData(responseData);
    })();
  }, []);

  return (
    <QuotaContext.Provider
      value={{
        percentage: data.percentage_usage,
        maxUsage: data.max_usage,
        currentUsage: data.current_usage,
      }}>
      {' '}
      {children}
    </QuotaContext.Provider>
  );
}

export function useQuota() {
  return useContext(QuotaContext);
}
