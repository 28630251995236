import { lazy } from 'react';

import { Loadable } from 'shared/ui-component/Loadable';

const Company = Loadable(lazy(() => import('modules/companies/pages/Company')));

const companyRouter = [
  {
    path: '/companies',
    element: <Company />,
  },
];

export default companyRouter;
